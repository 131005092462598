let Config = {
 API:'https://payapi.logisticprotrade.com',
 //API:'http://localhost:5000',
  MinInvoice:25,
  gtag:'AW-000000',
  Company:'Logistic Pro Pay',
  Website:'https://logisticprotrade.com',
  WidgetUrl:'https://apps.logisticprotrade.com/widget/lptpay.js?v4',
  WidgetName:'lpt',
  VerifyUrl:'https://4726btyathy.typeform.com/LogistPayVerify',
  POSUrl:'',
  SignupUrl:'/#/a/register-page',
  wordpress:'',
  ShowSignup:true
};
module.exports = Config;