import React, { Component } from "react";
import { Grid,  Row,  Col } from "react-bootstrap";

import Card from "components/Card/Card.jsx";
const Config = require('config');




class Settings extends Component {


  constructor(props){
    super(props);
 this.state = {
            
            kyc:false
              
            }}

            componentWillMount(){

              var user=JSON.parse(localStorage.getItem('user'));
          
              if (user.data.kyc=='Verified'){this.setState({kyc:true})}else{
          this.setState({kyc:false})}
          
            }

  render() {

    const {kyc} = this.state; 
    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
          <Col lg={12}>  <h3>Settings</h3> </Col>
          <Col md={12}>
          <Card
                
                
                content={
                  <Row>
                    <Col md={5}>
                      <div className="table-responsive">
                     <a href="/#/settings/accountinfo">Account information</a><br/>
Update your name, address, and other contact details.
                      </div>
                    </Col>
                    <Col md={5}>
                      <div className="table-responsive">
                       <a href="/#/settings/bankinfo">Banking/ Wallet info</a><br/>
Edit bank account info .
                      </div>
                    </Col>

                    <Col md={5}>
                      <div className="table-responsive">
                      {kyc==true?<a href="/#/settings/api">API Keys</a>:<div>API Keys (You must verify your account) <br/><a href={Config.VerifyUrl} target="_blank">Verify Account</a></div>}<br/>
View and create api keys.
                      </div>
                    </Col>
                    <Col md={5}>
                      <div className="table-responsive">
                      {kyc==true?<a href="/#/settings/paynowwidget">Pay Now Widget</a>:<div>Pay Now Widget (You must verify your account) <br/><a href={Config.VerifyUrl} target="_blank">Verify Account</a></div>}<br/>
Edit Widget Settings
                      </div>
                      
                    </Col>
                    {Config.wordpress!=''?  <Col md={5}>
                      <div className="table-responsive">
                    <a href={Config.wordpress} target="_blank">Wordpress Plugin</a><br/>
Get the Plugin
                      </div>
                    </Col>
                    :''}
                   {Config.POSUrl!=''?   <Col md={5}>
                      <div className="table-responsive">
                      {kyc==true?<a href="/#/settings/pos">POS Terminal</a>:<div>Settings (You must verify your account) <br/><a href={Config.VerifyUrl} target="_blank">Verify Account</a></div>}<br/>
Edit POS Terminal Settings
                      </div>
                    </Col> :''}



                    <Col md={5}>
                      <div className="table-responsive">
              <a href="/#/settings/assets">Asset Limits</a><br/>
Limit settings
                      </div>
                    </Col>
                  </Row>
                }
              /></Col>
   
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Settings;
