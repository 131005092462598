import Dashboard from "views/Dashboard/Dashboard.jsx";
import Settings from "views/Settings/Settings.jsx";
import BankingInfo from "views/Settings/banking-info.jsx";
import AccountInfo from "views/Settings/account-info.jsx";
import AssetSettings from "views/Settings/asset-settings.jsx";
import PayNowWidget from "views/Settings/paynowwidget.jsx";
import POS from "views/Settings/pos.jsx";
import Profile from "views/Profile/profile.jsx";
import Balances from "views/Balances/admin-balances.jsx";
import BalancesHistorical from "views/Balances/adminbalanceshistorical.jsx";
import Settlements from "views/Settlement/settlement.jsx";
import Addresses from "views/Addresses/addresses.jsx";
import Transactions from "views/Transactions/transactions.jsx";
import TransactionSearch from "views/Transactions/transaction-search.jsx";
import  ApiInfo from "views/Settings/api-info.jsx";
import  ApiKeys from "views/Settings/apis.jsx";
import  Billing from "views/Billing/billing.jsx";
import  Invoices from "views/Billing/invoices.jsx";
import  InvoiceInfo from "views/Billing/invoice-info.jsx";
import  Customers from "views/Billing/customers.jsx";
import  CustomerInfo from "views/Billing/customers-info.jsx";
import  MerchnatUsers from "views/Users/users.jsx";
import MerchnatUserInfo from "views/Users/user-info.jsx";
import  POSB from "views/Billing/pos.jsx";
import  POSInfo from "views/Billing/pos-info.jsx";

import pagesRoutes from "./pages.jsx";


var adminDashboardRoutes = [
  {
    menuhide:true,
    path: "/settings/api/:apiid",
    name: "API Key Info",
    
    component: ApiInfo
  },

  {     menuhide:true,
        path: "/settings/bankinfo",
        name: "Banking Info",
        icon: "fal fa-cogs",
        component: BankingInfo
      },
      {
        menuhide:true,
        path: "/settings/api",
    name: "API Keys",
      icon: "pe-7s-settings",
        component: ApiKeys
      },
      {
        menuhide:true,
        path: "/settings/paynowwidget",
    name: "Pay Now Widget",
      icon: "pe-7s-settings",
        component: PayNowWidget
      }, {
        menuhide:true,
        path: "/settings/pos",
    name: "POS Settings",
      icon: "pe-7s-settings",
        component: POS
      },
      {
        menuhide:true,
        path: "/settings/accountinfo",
    name: "Account Info",
      icon: "pe-7s-settings",
        component: AccountInfo
      },{
        menuhide:true,
        path: "/settings/assets",
    name: "Asset Settings",
      icon: "pe-7s-settings",
        component: AssetSettings
      },{
        collapse: true,
      menuhide:false,
      state: "openBalances",
      name: "Account Balances",
      icon: "fal fa-balance-scale",
      
      views: [
        {
          menuhide:false,
          path: "/account_balances/current",
          name: "Current",
          icon: "fal fa-balance-scale",
          component: Balances
        },
        {
          menuhide:false,
          path: "/account_balances/historical",
          name: "Historical",
          icon: "fal fa-balance-scale",
          component: BalancesHistorical
        }]
      },{
 
    path: "/settlement_history",
    name: "Settlement History",
    icon: "fal fa-file-invoice-dollar",
    component: Settlements
   
  },{
    collapse: true,
  menuhide:false,
  state: "payments",
  name: "Payments",
  icon: "fal fa-hand-holding-usd",
  
  views: [
    {
      menuhide:false,
      path: "/payments_received",
    name: "Payments Received",
      icon: "fal fa-hand-holding-usd",
      component: Transactions
    },
    {
      menuhide:false,
      path: "/transaction search",
      name: "Transaction Search",
      icon: "fal fa-hand-holding-usd",
      component: TransactionSearch
    }]
  },{
 
    path: "/addresses",
    name: "Addresses",
    icon: "fal fa-wallet",
    component: Addresses
   
  },
      {
        menuhide:true,
        path: "/profile",
        name: "Profile",
        component: Profile
      },

      {
        menuhide:true,
        path: "/billing/invoices/:invid",
        name: "Invoice Info",
        icon: "fal fa-file-invoice-dollar",
        component: InvoiceInfo
       
      },
{
        menuhide:true,
        path: "/billing/customers/:clid",
        name: "Customer Info",
        icon: "fal fa-file-invoice-dollar",
        component: CustomerInfo
       
      },
      {
        menuhide:true,
        path: "/billing/invoices",
        name: "Invoices",
        icon: "fal fa-file-invoice-dollar",
        component: Invoices
       
      },


      

      {
        menuhide:true,
        path: "/billing/customers",
        name: "Customers",
        icon: "fal fa-file-invoice-dollar",
        component: Customers
       
      }, {
        menuhide:true,
        path: "/billing/pos/:invid",
        name: "POS Info",
        icon: "fal fa-file-invoice-dollar",
        component: POSInfo
       
      },
      {
        menuhide:true,
        path: "/billing/pos",
        name: "POS",
        icon: "far fa-credit-card-blank",
        component: POSB
       
      },

      {
        menuhide:false,
        path: "/billing",
        name: "Billing",
        icon: "fal fa-file-invoice-dollar",
        component: Billing
       
      },
     
      {
        menuhide:true,
        path: "/users/:uid",
        name: "UserInfo",
        
        component: MerchnatUserInfo
      }, {
        menuhide:false,
        path: "/users",
        name: "Users",
        icon: "fas fa-users",
        component: MerchnatUsers
      },
     
      {
 
        path: "/settings",
        name: "Settings",
        icon: "fal fa-cog",
        component: Settings
       
      },

      
      
  { redirect: true, path: "/", pathTo: "/account_balances/current", name: "Home" }


];
export default adminDashboardRoutes;
